import * as React from "react"

import Layout from "../components/layout"

const IndexPage = () => {
  return (
    <>
      <div className="content w-full lg:p-12 md:p-3">
        <div className="p-4 bg-white shadow-lg">
          <h2 className="section-headline-experience">Senior Software Developer @ WMDA<p className="font-light">2020 - Present</p></h2>
          <p>
          WMDA is an organization that coordinates the collection of HLA/DNA data of volunteer donors and cord blood units around the world.  
          We provide a main application for collection of data from donor registries around the world.  
          Also the search and match application that allows doctors to find the best matches for their patients world wide.
          </p>
          <h3 className="section-headline-experience">Tasks:</h3>
          <ul className="list-disc list-inside">
            <li>Software Developer in PHP and Python.</li>
            <li>Design and development of API's</li>
            <li>Implementation of new features in both back end and front end for search and match application.</li>
            <li>Implementation of new features in data collection software.</li>
            <li>Refactoring of code for better standards and efficiency in the cloud.</li>
            <li>Creation of container images for Azure App Service.</li>
            <li>Planning and architecture of new applications.</li>
          </ul>
        </div>
      </div>
      <div className="content w-full lg:p-12 md:p-3">
        <div className="p-4 bg-white shadow-lg">
          <h2 className="section-headline-experience">Software Developer @ Billing House<p className="font-light">2017 - 2020</p></h2>
          <p>
          BillingHouse develops billing software for companies in the energy sector and parking industry.  
          Using a variety of technologies we build enterprise level applications and API’s for many companies throughout Europe. 
          Main technologies used are Spring Framework, SuiteCRM, ServiceMix, and Apache Camel.
          </p>
          <h3 className="section-headline-experience">Tasks:</h3>
          <ul className="list-disc list-inside">
            <li>Software Developer in PHP, JAVA, and Python.</li>
            <li>Design and development of API's</li>
            <li>Implementation of new features in both back end and front end.</li>
            <li>Refactoring and redesign of bike sharing back end application.</li>
            <li>Implementation of mobile API for Essent.</li>
            <li>Deployments of applications to Azure and Amazon cloud services.</li>
            <li>Create container images and create deployments to Kubernetes.</li>
          </ul>
        </div>
      </div>
      <div className="content w-full lg:p-12 md:p-3">
        <div className="p-4 bg-white shadow-lg">
          <h2 className="section-headline-experience">Lead Web Developer @ Get Credit Healthy<p className="font-light">2015 - 2017</p></h2>
          <p>
          Get Credit Healthy is a credit repair company.  
          Offering subscription services to the public with strategies to communicate with the 3 credit bureaus in United States.  
          As a lead developer I was in charge of planning and development of the multi-tenant application, 
          to provide information to customers, banking representatives, and credit coaches.
          </p>
          <h3 className="section-headline-experience">Tasks:</h3>
          <ul className="list-disc list-inside">
            <li>Lead Developer of main application.</li>
            <li>Salesforce integrations.</li>
            <li>MySQL Database Maintenance.</li>
            <li>Planning and architecture of main application.</li>
            <li>Automating business workflow and logic.</li>
            <li>Maintenance of Windows 2012 R2 servers in-house.</li>
          </ul>
        </div>
      </div>
      <div className="content w-full lg:p-12 md:p-3">
        <div className="p-4 bg-white shadow-lg">
          <h2 className="section-headline-experience">CWO @ Cybertrack<p className="font-light">2015 - 2017</p></h2>
          <p>
          CyberTrack is a company focused on providing tracking services for big fleets and private clients. 
          With applications built in Java8, PHP and MySQL, we provide accurate information to our clients.  
          Using innovative technologies to display our data for ease of use.  My duties included the maintenance of in-house hardware servers.  
          Development of application for client’s use and support.
          </p>
          <h3 className="section-headline-experience">Tasks:</h3>
          <ul className="list-disc list-inside">
            <li>Lead Developer of front end application built in Laravel 5 (PHP framework) and MySQL.</li>
            <li>Supporting Windows 2012 R2 servers in-house.</li>
            <li>Training of staff.</li>
            <li>Implementation of CRM application and integration with external services to provide information for staff.</li>
            <li>Configuration of tracking devices hardware (GSM).</li>
          </ul>
        </div>
      </div>
    </>
  )
}

IndexPage.Layout = Layout

export default IndexPage